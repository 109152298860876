// AsignacionesModal.jsx
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit, Delete } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import api from './api'; // Asegúrate de que este archivo exista y esté configurado correctamente

const AsignacionesModal = ({
  open,
  handleClose,
  fetchAsignacionesParent = () => {}, // Valor por defecto
}) => {
  const [asignaciones, setAsignaciones] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [tiposConteo, setTiposConteo] = useState([]);
  const [conteos, setConteos] = useState([]);
  const [selectedAsignacion, setSelectedAsignacion] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    asignacionId: null,
  });

  // Esquema de validación con Yup
  const AsignacionSchema = Yup.object().shape({
    idUsuario: Yup.string().required('Usuario es requerido'),
    idConteo: Yup.string().required('Conteo es requerido'),
    idTipoConteo: Yup.string().required('Tipo de Conteo es requerido'),
  });

  useEffect(() => {
    if (open) {
      fetchAllData();
      fetchAsignacionesList();
    }
  }, [open]);

  const fetchAllData = async () => {
    try {
      const [usuariosRes, tiposRes, conteosRes] = await Promise.all([
        api.get('/usuarios'),
        api.get('/tipos_conteo'),
        api.get('/conteos_lista'),
      ]);

      if (usuariosRes.data.success) setUsuarios(usuariosRes.data.usuarios);
      if (tiposRes.data.success) setTiposConteo(tiposRes.data.tipos || []);
      if (conteosRes.data.success) setConteos(conteosRes.data.conteos || []);
    } catch (error) {
      console.error('Error al obtener datos:', error);
      showSnackbar('Error al obtener datos', 'error');
    }
  };

  const fetchAsignacionesList = async () => {
    try {
      const response = await api.get('/asignaciones_conteo');
      if (response.data.success) {
        setAsignaciones(response.data.asignaciones);
      }
    } catch (error) {
      console.error('Error al obtener las asignaciones:', error);
      showSnackbar('Error al obtener las asignaciones', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleEditClick = (asignacion) => {
    setSelectedAsignacion(asignacion);
  };

  const handleDeleteClick = (asignacionId) => {
    setDeleteDialog({
      open: true,
      asignacionId,
    });
  };

  const confirmDelete = async () => {
    try {
      const response = await api.delete(
        `/asignaciones_conteo/${deleteDialog.asignacionId}`
      );
      if (response.data.success) {
        showSnackbar('Asignación eliminada correctamente', 'success');
        fetchAsignacionesList();
        fetchAsignacionesParent();
      } else {
        showSnackbar(
          response.data.message || 'Error al eliminar la asignación',
          'error'
        );
      }
    } catch (error) {
      console.error('Error al eliminar la asignación:', error);
      showSnackbar('Error al conectar con el servidor', 'error');
    } finally {
      setDeleteDialog({
        open: false,
        asignacionId: null,
      });
    }
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      if (selectedAsignacion) {
        // Actualizar asignación
        const response = await api.put(
          `/asignaciones_conteo/${selectedAsignacion.asignacionId}`,
          {
            idUsuario: values.idUsuario,
            idConteo: values.idConteo,
            idTipoConteo: values.idTipoConteo,
          }
        );
        if (response.data.success) {
          showSnackbar('Asignación actualizada correctamente', 'success');
          fetchAsignacionesList();
          fetchAsignacionesParent();
          resetForm();
          setSelectedAsignacion(null);
        } else {
          showSnackbar(
            response.data.message || 'Error al actualizar la asignación',
            'error'
          );
        }
      } else {
        // Crear nueva asignación
        const response = await api.post('/asignaciones_conteo', {
          idUsuario: values.idUsuario,
          idConteo: values.idConteo,
          idTipoConteo: values.idTipoConteo,
        });
        if (response.data.success) {
          showSnackbar('Asignación creada correctamente', 'success');
          fetchAsignacionesList();
          fetchAsignacionesParent();
          resetForm();
        } else {
          showSnackbar(
            response.data.message || 'Error al crear la asignación',
            'error'
          );
        }
      }
    } catch (error) {
      console.error('Error al guardar la asignación:', error);
      showSnackbar('Error al conectar con el servidor', 'error');
    }
  };

  const columns = [
    { field: 'nombreUsuario', headerName: 'Usuario', flex: 1 },
    { field: 'nombreConteo', headerName: 'Conteo', flex: 1 },
    { field: 'nombreTipo', headerName: 'Tipo de Conteo', flex: 1 },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Editar">
            <IconButton
              color="primary"
              onClick={() => handleEditClick(params.row)}
              size="small"
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row.asignacionId)}
              size="small"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {selectedAsignacion ? 'Editar Asignación' : 'Nueva Asignación'}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              idUsuario: selectedAsignacion ? selectedAsignacion.usuarioId : '',
              idConteo: selectedAsignacion ? selectedAsignacion.conteoId : '',
              idTipoConteo: selectedAsignacion ? selectedAsignacion.tipoConteoId : '',
            }}
            validationSchema={AsignacionSchema}
            enableReinitialize
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      name="idUsuario"
                      value={values.idUsuario}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={touched.idUsuario && Boolean(errors.idUsuario)}
                      helperText={touched.idUsuario && errors.idUsuario}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Seleccione un usuario</option>
                      {usuarios.map((usuario) => (
                        <option key={usuario.id} value={usuario.id}>
                          {usuario.nombreUsuario}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      name="idConteo"
                      value={values.idConteo}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={touched.idConteo && Boolean(errors.idConteo)}
                      helperText={touched.idConteo && errors.idConteo}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Seleccione un conteo</option>
                      {conteos.map((conteo) => (
                        <option key={conteo.id} value={conteo.id}>
                          {conteo.nombreConteo}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      name="idTipoConteo"
                      value={values.idTipoConteo}
                      onChange={handleChange}
                      fullWidth
                      required
                      error={touched.idTipoConteo && Boolean(errors.idTipoConteo)}
                      helperText={touched.idTipoConteo && errors.idTipoConteo}
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Seleccione un tipo de conteo</option>
                      {tiposConteo.map((tipo) => (
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.nombreTipo}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  {selectedAsignacion && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setSelectedAsignacion(null)}
                      sx={{ mr: 2 }}
                    >
                      Cancelar
                    </Button>
                  )}
                  <Button type="submit" variant="contained" color="primary">
                    {selectedAsignacion ? 'Actualizar' : 'Guardar'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>

          <Box sx={{ mt: 5 }}>
            <Typography variant="h6" gutterBottom>
              Lista de Asignaciones
            </Typography>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={asignaciones}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                getRowId={(row) => row.asignacionId}
                sx={{
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
              />
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar de notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Diálogo de Confirmación de Eliminación */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, asignacionId: null })}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar esta asignación?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteDialog({ open: false, asignacionId: null })}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AsignacionesModal;
