// EnhancedArticleSelectionPage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Grid,
  FormControl,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  TextField,
  Chip,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import api from './apiV'; // Asegúrate de que este archivo esté correctamente configurado
import ArticleSelectionModal from './ArticleSelectionModal';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

// Importaciones de ag-Grid
import { AgGridReact } from 'ag-grid-react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Importar ExcelJS y FileSaver para la exportación a Excel
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

// Registrar los módulos con ag-Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const EnhancedArticleSelectionPage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  // Validación de inicio de sesión
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData && storedUserData !== 'undefined') {
      try {
        const parsedUserData = JSON.parse(storedUserData);
        if (parsedUserData && parsedUserData.username) {
          setUserData(parsedUserData);
        } else {
          throw new Error('Datos de usuario inválidos');
        }
      } catch (error) {
        console.error('Error al analizar los datos de usuario:', error);
        localStorage.removeItem('userData');
        navigate('/', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  // Manejo del menú de usuario
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    navigate('/', { replace: true });
  };

  // Estados del componente
  const [fabricantes, setFabricantes] = useState([]);
  const [filterType, setFilterType] = useState('fabricante');
  const [selectedFabricante, setSelectedFabricante] = useState('');
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [columns, setColumns] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);

  // Cargar fabricantes con filtrado
  useEffect(() => {
    const fetchFabricantes = async () => {
      try {
        const response = await api.get('/fabricantes');

        // Filtra los fabricantes que contengan "SHL" en su nombre
        // (cambia la condición según necesites, por ej. !== 'DISTRIBUIDORA SHL SA')
        const dataFiltrada = response.data.filter(
          (fab) => !fab.fabricante.includes('SHL')
        );

        setFabricantes(dataFiltrada);
      } catch (error) {
        console.error('Error al obtener fabricantes:', error);
        setNotification({
          open: true,
          message: 'Error al cargar fabricantes.',
          severity: 'error',
        });
      }
    };

    fetchFabricantes();
  }, []);

  const handleFilterTypeChange = (event, newFilterType) => {
    if (newFilterType !== null) {
      setFilterType(newFilterType);
      setSelectedFabricante('');
      setSelectedArticulos([]);
      setResults([]);
      setColumns([]);
    }
  };

  const handleOpenArticleModal = () => {
    setIsArticleModalOpen(true);
  };

  const handleCloseArticleModal = () => {
    setIsArticleModalOpen(false);
  };

  const handleArticleSelection = (selectedArticles) => {
    setSelectedArticulos(selectedArticles);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const params = {
        cod_fabricante: selectedFabricante || null,
        cod_articulo_list:
          selectedArticulos.length > 0
            ? selectedArticulos.map((a) => a.cod_articulo).join(',')
            : null,
      };

      if (!params.cod_fabricante && !params.cod_articulo_list) {
        setNotification({
          open: true,
          message: 'Debes seleccionar al menos un fabricante o artículos.',
          severity: 'warning',
        });
        setLoading(false);
        return;
      }

      const response = await api.get('/get-cnc', { params });

      if (response.data && response.data.length > 0) {
        const dataWithId = response.data.map((row, index) => ({
          id: row.cod_articulo || index,
          ...row,
        }));
        setResults(dataWithId);
        setColumns(generateColumnDefs(dataWithId[0]));
        setNotification({
          open: true,
          message: 'Datos obtenidos correctamente.',
          severity: 'success',
        });
      } else {
        setResults([]);
        setColumns([]);
        setNotification({
          open: true,
          message: 'No se encontraron datos.',
          severity: 'info',
        });
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setNotification({
        open: true,
        message: 'Error al obtener los datos.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const generateColumnDefs = (dataRow) => {
    const columns = [];

    // Añadir columnas de datos
    Object.keys(dataRow)
      .filter((key) => key !== 'id')
      .forEach((key) => {
        let columnDef = {
          headerName: formatHeaderName(key),
          field: key,
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 100,
          flex: 1,
          valueFormatter: ({ value }) =>
            typeof value === 'object' ? JSON.stringify(value) : value,
          cellStyle: { fontSize: '0.875rem', padding: '4px' },
        };

        // Formatear la columna de fecha
        if (key === 'Última Fecha Compra') {
          columnDef.valueFormatter = (params) => {
            if (!params.value) return '';
            const date = new Date(params.value);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          };
        }

        columns.push(columnDef);
      });

    return columns;
  };

  const formatHeaderName = (key) => {
    const mappings = {
      cod_articulo: 'Código de Artículo',
      articulo: 'Artículo',
    };
    return mappings[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // Función para exportar los datos a Excel utilizando ExcelJS
  const exportToExcel = async () => {
    if (!gridApi) return;

    const rowData = [];
    gridApi.forEachNode((node) => rowData.push(node.data));

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Datos');

    // Agregar una fila vacía para el margen superior
    worksheet.addRow([]);

    // Preparar encabezados sin margen izquierdo
    const headers = columns.map((col) => col.headerName);
    const headerRow = worksheet.addRow(headers);

    // Insertar una columna vacía al inicio para el margen izquierdo
    worksheet.spliceColumns(1, 0, []);

    // Aplicar estilos a los encabezados (omitiendo la primera columna)
    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (colNumber > 1) {
        // Ignorar la primera columna (margen izquierdo)
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' },
        };
        cell.font = { bold: true };
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      }
    });

    // Agregar datos
    rowData.forEach((dataItem) => {
      const row = columns.map((col) => {
        let value = dataItem[col.field];
        if (col.field === 'Última Fecha Compra' && value) {
          const date = new Date(value);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          value = `${day}-${month}-${year}`;
        }
        return value;
      });

      const dataRow = worksheet.addRow(row);

      // Insertar una celda vacía al inicio para el margen izquierdo
      dataRow.splice(1, 0, '');

      // Aplicar bordes a las celdas de datos (omitiendo la primera columna)
      dataRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber > 1) {
          // Ignorar la primera columna (margen izquierdo)
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
          };
        }
      });
    });

    // Ajustar el ancho de las columnas
    worksheet.columns.forEach((column, index) => {
      if (index > 0) {
        column.width = 20;
      }
    });

    // Generar el archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(data, 'Datos_Exportados.xlsx');
  };

  return (
    <Box sx={{ bgcolor: '#f0f2f5', minHeight: '100vh' }}>
      {/* Barra de navegación */}
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: 'bold' }}
          >
            Reportes de Clientes No Compradores
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Abrir configuración">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userData ? userData.username : 'Usuario'}>
                  <AccountCircleIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem disabled>
                <Typography textAlign="center">
                  {userData ? userData.username : 'Usuario'}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                <Typography textAlign="center">Cerrar Sesión</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={2} sx={{ p: 3 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography
              variant="h4"
              display="flex"
              alignItems="center"
              sx={{ fontWeight: 'bold' }}
            >
              <FilterListIcon sx={{ mr: 1, fontSize: 32 }} />
              Reportes de Clientes No Compradores
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={exportToExcel}
              startIcon={<FilterListIcon />}
              disabled={results.length === 0}
            >
              Exportar a Excel
            </Button>
          </Grid>

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <ToggleButtonGroup
              value={filterType}
              exclusive
              onChange={handleFilterTypeChange}
              aria-label="Filtrar por"
              size="small"
            >
              <ToggleButton value="fabricante" aria-label="Fabricante">
                Fabricante
              </ToggleButton>
              <ToggleButton value="articulos" aria-label="Artículos">
                Artículos
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          <Grid container spacing={2} sx={{ mb: 2 }}>
            {filterType === 'fabricante' && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Autocomplete
                  id="fabricante-autocomplete"
                  options={fabricantes}
                  getOptionLabel={(option) => option.fabricante}
                  onChange={(event, newValue) => {
                    setSelectedFabricante(
                      newValue ? newValue.cod_fabricante : ''
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Fabricante"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <FilterListIcon
                              sx={{ color: 'action.active', mr: 1 }}
                            />
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {filterType === 'articulos' && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenArticleModal}
                  startIcon={<SearchIcon />}
                >
                  Seleccionar Artículos
                </Button>
              </Grid>
            )}

            {filterType === 'articulos' && selectedArticulos.length > 0 && (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selectedArticulos.map((articulo) => (
                    <Chip
                      key={articulo.cod_articulo}
                      label={articulo.articulo}
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Grid>
            )}

            <Grid item xs />

            <Grid item>
              <Tooltip
                title={
                  (filterType === 'fabricante' && !selectedFabricante) ||
                  (filterType === 'articulos' && selectedArticulos.length === 0)
                    ? 'Debes seleccionar un fabricante o al menos un artículo.'
                    : ''
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={
                      ((filterType === 'fabricante' && !selectedFabricante) ||
                        (filterType === 'articulos' &&
                          selectedArticulos.length === 0)) ||
                      loading
                    }
                    startIcon={<SearchIcon />}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Buscar'}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>

          <Box
            className="ag-theme-alpine"
            sx={{ height: '50vh', width: '100%' }}
          >
            <AgGridReact
              onGridReady={onGridReady}
              rowData={results}
              columnDefs={columns}
              pagination={true}
              paginationPageSize={20}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
                cellStyle: { fontSize: '0.875rem', padding: '4px' },
              }}
              headerHeight={35}
              rowHeight={30}
            />
          </Box>
        </Paper>

        <ArticleSelectionModal
          open={isArticleModalOpen}
          handleClose={handleCloseArticleModal}
          onSelectionComplete={handleArticleSelection}
        />

        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default EnhancedArticleSelectionPage;
